var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          "overflow-y": "overlay",
          height: "62.2vh",
          width: "100%",
        },
        style: _vm.backCss,
      },
      [
        _c(
          "a-spin",
          {
            staticStyle: { "min-height": "300px" },
            attrs: { spinning: _vm.spinning },
          },
          [
            _vm.platform.isLocal
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      "min-width": "520px",
                      "padding-top": "12px",
                    },
                  },
                  _vm._l(_vm.formData.difficulties, function (diffItem, index) {
                    return _c(
                      "div",
                      {
                        key: diffItem.id,
                        staticStyle: { "padding-bottom": "40px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "coef_item",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "16px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(diffItem.name) +
                                    "\n              " +
                                    _vm._s(_vm.getStatusText(diffItem)) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm.factorStatus.some((item) => {
                              return (
                                item.status == "REVIEW_FINISH" &&
                                item.type == diffItem.type
                              )
                            })
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "16px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.factorStatus.find((item) => {
                                            return (
                                              item.status == "REVIEW_FINISH" &&
                                              item.type == diffItem.type
                                            )
                                          }).value
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "16px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(diffItem.difficultyFactorValue)
                                    ),
                                  ]
                                ),
                            _c("div", { staticClass: "btn-list" }, [
                              _c(
                                "div",
                                [
                                  _vm.isTranslatorOrProofreader
                                    ? _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.btnLoading,
                                            disabled: _vm.factorStatus.some(
                                              (item) => {
                                                return (
                                                  item.type == diffItem.type &&
                                                  item.status !=
                                                    "PREPARE_REJECT" &&
                                                  item.status !=
                                                    "PREPARE_REJECT_HR"
                                                )
                                              }
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.submit(
                                                diffItem,
                                                diffItem.difficultyFactorValue
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("提交")]
                                      )
                                    : _vm._e(),
                                  _vm.isJianzhiOrJiaodui
                                    ? _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                          },
                                          attrs: {
                                            loading: _vm.btnLoading,
                                            disabled:
                                              _vm.factorStatus.some((item) => {
                                                return (
                                                  item.type == diffItem.type &&
                                                  item.status !=
                                                    "PREPARE_CONFIRM"
                                                )
                                              }) || !_vm.factorStatus.length,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reject(diffItem)
                                            },
                                          },
                                        },
                                        [_vm._v("驳回")]
                                      )
                                    : _vm._e(),
                                  _vm.isJianzhiOrJiaodui
                                    ? _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                          },
                                          attrs: {
                                            loading: _vm.btnLoading,
                                            disabled:
                                              _vm.factorStatus.some((item) => {
                                                return (
                                                  item.type == diffItem.type &&
                                                  item.status !=
                                                    "PREPARE_CONFIRM"
                                                )
                                              }) || !_vm.factorStatus.length,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.review(
                                                diffItem,
                                                diffItem.difficultyFactorValue
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("系数评审")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._l(
                          diffItem.difficulties,
                          function (item, formIndex) {
                            return _c(
                              "div",
                              { key: formIndex, staticClass: "coef_item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "padding-top": "26px",
                                      display: "flex",
                                      "justify-content": "space-between",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "16px",
                                          display: "flex",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "35px",
                                              "font-weight": "bold",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  `${
                                                    _vm.numbers[formIndex] ||
                                                    formIndex + 1
                                                  }、`
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.title))]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-weight": "bold",
                                          "font-size": "16px",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.factorValue))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "max-width": "100%" } },
                                  _vm._l(
                                    item.categories,
                                    function (child, childIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: child.id,
                                          staticStyle: { width: "100%" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                "padding-top": "16px",
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "font-size": "14px",
                                                    width: "15vw",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "35px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          `${formIndex + 1}.${
                                                            childIndex + 1
                                                          }`
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        child.factorChapters &&
                                                        child.factorChapters
                                                          .length
                                                          ? "open-hover"
                                                          : "",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openClick(
                                                            child
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            child.factor
                                                              ? child.factor
                                                                  .smallType
                                                              : ""
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                      child.factorChapters &&
                                                      child.factorChapters
                                                        .length
                                                        ? _c(
                                                            "span",
                                                            [
                                                              !child.isOpen
                                                                ? _c("a-icon", {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "11px",
                                                                        position:
                                                                          "relative",
                                                                        top: "0px",
                                                                        left: "3px",
                                                                      },
                                                                    attrs: {
                                                                      type: "down",
                                                                    },
                                                                  })
                                                                : _c("a-icon", {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "11px",
                                                                        position:
                                                                          "relative",
                                                                        top: "0px",
                                                                        left: "3px",
                                                                      },
                                                                    attrs: {
                                                                      type: "up",
                                                                    },
                                                                  }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    display: "flex",
                                                    flex: "1",
                                                    "justify-content":
                                                      "space-between",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(" ") +
                                                          "\n                      "
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticStyle: {
                                                              width: "35px",
                                                            },
                                                          }),
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  child.formMatchValue ||
                                                                    ""
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        child.factorValue
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          child.isOpen
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "chapter-list-div",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "chapter-list",
                                                    },
                                                    [
                                                      _vm._l(
                                                        child.factorChapters,
                                                        function (chapter) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                key: chapter
                                                                  .chapter
                                                                  .sequenceNo,
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "章节" +
                                                                      _vm._s(
                                                                        chapter
                                                                          .chapter
                                                                          .sequenceNo
                                                                      )
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "min-height":
                                                                          "18px",
                                                                        "margin-top":
                                                                          "3px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        chapter.detailValue
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      "min-width": "520px",
                      "padding-top": "12px",
                    },
                  },
                  _vm._l(_vm.formData.difficulties, function (item) {
                    return _c(
                      "a-row",
                      {
                        key: item.id,
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("a-col", { attrs: { span: 3 } }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "font-weight": "bold",
                              },
                            },
                            [_vm._v("难易程度")]
                          ),
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: 8 } },
                          [
                            !_vm.isEdit
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.difficultLevel) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "70%" },
                                    attrs: { placeholder: "请选择难度" },
                                    model: {
                                      value: _vm.difficultLevel,
                                      callback: function ($$v) {
                                        _vm.difficultLevel = $$v
                                      },
                                      expression: "difficultLevel",
                                    },
                                  },
                                  _vm._l(
                                    item.difficulties[0].categories[0].options,
                                    function (i) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: i.title,
                                          attrs: { value: i.title },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(i.title) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                          ],
                          1
                        ),
                        _c("a-col", { attrs: { span: 1 } }, [
                          item.difficulties[0].categories &&
                          item.difficulties[0].categories.length
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "font-weight": "bold",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        item.difficulties[0].categories[0].options.find(
                                          (i) => {
                                            return i.title == _vm.difficultLevel
                                          }
                                        ) &&
                                          item.difficulties[0].categories[0].options.find(
                                            (i) => {
                                              return (
                                                i.title == _vm.difficultLevel
                                              )
                                            }
                                          ).factor
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("a-col", { attrs: { span: 3 } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "14px",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.factorStatus.some((item) => {
                                      return item.status == "REVIEW_FINISH"
                                    })
                                      ? "（已确认）"
                                      : ""
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: 9 } },
                          [
                            _vm.isEdit
                              ? _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-right": "15px" },
                                    attrs: {
                                      loading: _vm.btnLoading,
                                      icon: "edit",
                                    },
                                    on: {
                                      click: () => {
                                        _vm.isEdit = false
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                )
                              : _vm._e(),
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "15px" },
                                attrs: {
                                  loading: _vm.btnLoading,
                                  type: "primary",
                                  icon: !_vm.isEdit ? "edit" : "save",
                                },
                                on: { click: _vm.saveDifficultLevel },
                              },
                              [_vm._v(_vm._s(!_vm.isEdit ? "编辑" : "保存"))]
                            ),
                            _vm.difficultLevel &&
                            (_vm.parent.isTongChou || _vm.isAdmin)
                              ? _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      loading: _vm.btnLoading,
                                      disabled:
                                        _vm.isEdit ||
                                        (_vm.factorStatus.some((item) => {
                                          return (
                                            item.status != "PREPARE_REJECT_HR"
                                          )
                                        }) &&
                                          _vm.userInfo.roles.every((item) => {
                                            return item.roleCode != "admin"
                                          })),
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.noLocalReview("NOT_LOCAL")
                                      },
                                    },
                                  },
                                  [_vm._v("系数评审")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }