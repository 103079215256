<template>
  <div>
    <div style="overflow-y: overlay;height: 62.2vh;width:100%" :style="backCss">
      <a-spin :spinning="spinning" style="min-height:300px">
        <div v-if="platform.isLocal" style="width:50%;min-width:520px;padding-top:12px;">
          <div style="padding-bottom:40px;" v-for="(diffItem, index) in formData.difficulties" :key="diffItem.id">
            <div class="coef_item" style="display:flex;justify-content:space-between;">
              <div style="font-weight:bold;font-size:16px">
                {{ diffItem.name }}
                {{ getStatusText(diffItem) }}
              </div>
              <div
                v-if="
                  factorStatus.some(item => {
                    return item.status == 'REVIEW_FINISH' && item.type == diffItem.type
                  })
                "
                style="font-weight:bold;font-size:16px"
              >
                {{
                  factorStatus.find(item => {
                    return item.status == 'REVIEW_FINISH' && item.type == diffItem.type
                  }).value
                }}
              </div>
              <div v-else style="font-weight:bold;font-size:16px">{{ diffItem.difficultyFactorValue }}</div>
              <div class="btn-list">
                <div>
                  <a-button
                    type="primary"
                    :loading="btnLoading"
                    v-if="isTranslatorOrProofreader"
                    :disabled="
                      factorStatus.some(item => {
                        return (
                          item.type == diffItem.type &&
                          item.status != 'PREPARE_REJECT' &&
                          item.status != 'PREPARE_REJECT_HR'
                        )
                      })
                    "
                    style="margin-right:15px;"
                    @click="submit(diffItem, diffItem.difficultyFactorValue)"
                    >提交</a-button
                  >
                  <a-button
                    :loading="btnLoading"
                    :disabled="
                      factorStatus.some(item => {
                        return item.type == diffItem.type && item.status != 'PREPARE_CONFIRM'
                      }) ||
                        !factorStatus.length
                    "
                    v-if="isJianzhiOrJiaodui"
                    type="primary"
                    style="margin-right:15px;"
                    @click="reject(diffItem)"
                    >驳回</a-button
                  >
                  <!--陈立说取消管理员特殊权限 :disabled="
                      (factorStatus.some(item => {
                        return item.type == diffItem.type && item.status != 'PREPARE_CONFIRM'
                      }) &&
                        userInfo.roles.every(item => {
                          return item.roleCode != 'admin'
                        }))||!factorStatus.length
                    " -->
                  <a-button
                    :loading="btnLoading"
                    v-if="isJianzhiOrJiaodui"
                    :disabled="
                      factorStatus.some(item => {
                        return item.type == diffItem.type && item.status != 'PREPARE_CONFIRM'
                      }) || !factorStatus.length
                    "
                    type="primary"
                    style="margin-right:15px;"
                    @click="review(diffItem, diffItem.difficultyFactorValue)"
                    >系数评审</a-button
                  >
                </div>
              </div>
            </div>
            <div class="coef_item" v-for="(item, formIndex) in diffItem.difficulties" :key="formIndex">
              <div style="width:100%;padding-top:26px;display:flex;justify-content:space-between;align-items:center">
                <div style="font-size:16px;display:flex">
                  <div style="width:35px;font-weight:bold">
                    {{ `${numbers[formIndex] || formIndex + 1}、` }}
                  </div>
                  <div style="font-weight:bold">{{ item.title }}</div>
                </div>
                <div style="font-weight:bold;font-size:16px">{{ item.factorValue }}</div>
              </div>
              <div style="max-width:100%;">
                <div style="width:100%;" v-for="(child, childIndex) in item.categories" :key="child.id">
                  <div
                    style="width:100%;padding-top:16px;display:flex;justify-content:space-between;align-items:center;"
                  >
                    <div style="display:flex;font-size:14px;width:15vw">
                      <div style="width:35px">{{ `${formIndex + 1}.${childIndex + 1}` }}</div>
                      <div
                        :class="child.factorChapters && child.factorChapters.length ? 'open-hover' : ''"
                        @click="openClick(child)"
                      >
                        {{ child.factor ? child.factor.smallType : '' }}
                        <span v-if="child.factorChapters && child.factorChapters.length">
                          <a-icon
                            v-if="!child.isOpen"
                            type="down"
                            style="font-size:11px;position:relative;top:0px;left:3px;"
                          /><a-icon v-else type="up" style="font-size:11px;position:relative;top:0px;left:3px;" />
                        </span>
                      </div>
                    </div>
                    <div style="font-size:14px;display:flex;flex:1;justify-content:space-between;">
                      <div style="display:flex;justify-content:space-between">
                        {{ ' ' }}
                        <div style="display:flex">
                          <div style="width:35px;"></div>
                          <div>
                            {{ child.formMatchValue || '' }}
                          </div>
                        </div>
                      </div>
                      {{ child.factorValue }}
                    </div>
                  </div>
                  <div v-if="child.isOpen" class="chapter-list-div">
                    <div class="chapter-list">
                      <template v-for="chapter in child.factorChapters">
                        <div :key="chapter.chapter.sequenceNo">
                          <div>章节{{ chapter.chapter.sequenceNo }}</div>
                          <div style="min-height:18px;margin-top:3px;">{{ chapter.detailValue }}</div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--           <div class="coef_item" style="padding-top:5px;padding-bottom:40px;">
            <div
              v-has="'difficultyFactorValue'"
              style="width:100%;padding-top:16px;display:flex;justify-content:space-between;align-items:center"
            >
              <div style="font-weight:bold;font-size:16px;display:flex">
                <div>特殊难度系数</div>
              </div>
              <div @dblclick="difficultyFactorValueEdit(diffItem)">
                <a-input
                  :disabled="!diffItem.isEdit"
                  @blur="saveDifficultyFactor()"
                  style="font-size:16px;font-weight:bold;width:32px;height:24px;padding:0 1px 0 1px;text-align:center"
                  v-model="diffItem.specialFactorValue"
                />
              </div>
            </div>
          </div> -->
          </div>
        </div>
        <div v-else style="width:50%;min-width:520px;padding-top:12px;">
          <a-row v-for="item in formData.difficulties" :key="item.id" style="display: flex;align-items: center;">
            <a-col :span="3">
              <span style="font-size:14px;font-weight:bold">难易程度</span>
            </a-col>
            <a-col :span="8">
              <span v-if="!isEdit" style="font-size:14px;font-weight:bold">
                {{ difficultLevel }}
              </span>
              <a-select v-else style="width: 70%" placeholder="请选择难度" v-model="difficultLevel">
                <a-select-option
                  v-for="i in item.difficulties[0].categories[0].options"
                  :value="i.title"
                  :key="i.title"
                >
                  {{ i.title }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="1">
              <span
                style="font-size:14px;font-weight:bold"
                v-if="item.difficulties[0].categories && item.difficulties[0].categories.length"
              >
                {{
                  item.difficulties[0].categories[0].options.find(i => {
                    return i.title == difficultLevel
                  }) &&
                    item.difficulties[0].categories[0].options.find(i => {
                      return i.title == difficultLevel
                    }).factor
                }}
              </span></a-col
            >
            <a-col :span="3">
              <div style="font-weight:bold;font-size:14px">
                {{
                  factorStatus.some(item => {
                    return item.status == 'REVIEW_FINISH'
                  })
                    ? '（已确认）'
                    : ''
                }}
              </div>
            </a-col>
            <a-col :span="9">
              <a-button
                :loading="btnLoading"
                v-if="isEdit"
                @click="
                  () => {
                    isEdit = false
                  }
                "
                style="margin-right:15px"
                icon="edit"
                >取消</a-button
              >
              <a-button
                style="margin-right:15px"
                @click="saveDifficultLevel"
                :loading="btnLoading"
                type="primary"
                :icon="!isEdit ? 'edit' : 'save'"
                >{{ !isEdit ? '编辑' : '保存' }}</a-button
              >
              <a-button
                :loading="btnLoading"
                :disabled="
                  isEdit ||
                    (factorStatus.some(item => {
                      return item.status != 'PREPARE_REJECT_HR'
                    }) &&
                      userInfo.roles.every(item => {
                        return item.roleCode != 'admin'
                      }))
                "
                v-if="difficultLevel && (parent.isTongChou || isAdmin)"
                @click="noLocalReview('NOT_LOCAL')"
                type="primary"
                >系数评审</a-button
              >
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { postAction, getAction, putAction } from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { simpleDebounce } from '@/utils/util'
export default {
  props: {
    parent: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  data() {
    return {
      formData: {},
      isEdit: false,
      btnLoading: false,
      numbers: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      params: {
        ...this.$route.params,
        record: this.$route.query.record
      },
      spinning: false,
      factorStatus: [],
      difficultLevel: '',
      isJianzhiOrJiaodui: false,
      isTranslatorOrProofreader: false,
      userInfo: {},
      stages: [],
      positions: [],
      platform: {
        isLocal: true
      },
      options: [
        { label: '非常简单', value: 'EASIEST' },
        { label: '很简单', value: 'EASIER' },
        { label: '比较简单', value: 'EASY' },
        { label: '正常', value: 'NORMAL' },
        { label: '比较复杂', value: 'HARDER' },
        { label: '复杂', value: 'HARD' }
      ],
      isAdmin: false
    }
  },
  mounted() {
    this.getFormData()
    this.getDifficultyFactor()
    this.getDetail()
  },
  created() {
    const userInfo = Vue.ls.get(USER_INFO)
    this.userInfo = Object.assign({}, userInfo)
    this.isAdmin = this.userInfo.roles.some(item => {
      return item.roleCode == 'admin'
    })
    this.getStageList()
  },
  methods: {
    getStatusText(diffItem) {
      let text = ''
      if (this.factorStatus.length) {
        if (
          this.factorStatus.some(item => {
            return item.status == 'REVIEW_FINISH' && item.type == diffItem.type
          })
        ) {
          text = '（BOSS已确认）'
        } else if (
          this.factorStatus.some(item => {
            return item.status == 'REVIEW_CONFIRM' && item.type == diffItem.type
          })
        ) {
          text = '（HR已确认）'
        } else if (
          this.factorStatus.some(item => {
            return item.status == 'REVIEW_SUBMIT' && item.type == diffItem.type
          })
        ) {
          text = '（审核岗已确认）'
        } else if (
          this.factorStatus.some(item => {
            return item.status == 'PREPARE_CONFIRM' && item.type == diffItem.type
          })
        ) {
          text = '（普通岗已确认）'
        } else if (
          this.factorStatus.some(item => {
            return item.status == 'REVIEW_REJECT' && item.type == diffItem.type
          })
        ) {
          text = '（BOSS已驳回）'
        } else if (
          this.factorStatus.some(item => {
            return item.status == 'PREPARE_REJECT_HR' && item.type == diffItem.type
          })
        ) {
          text = '（HR已驳回）'
        } else if (
          this.factorStatus.some(item => {
            return item.status == 'PREPARE_REJECT' && item.type == diffItem.type
          })
        ) {
          text = '（校对/监制驳回）'
        } else if (
          this.factorStatus.some(item => {
            return item.status == 'REVIEW_SUBMIT_AUTO' && item.type == diffItem.type
          })
        ) {
          text = '（自动推送）'
        }
      }
      return text
    },
    saveDifficultLevel() {
      if (this.isEdit) {
        let self = this
        let formData = JSON.parse(JSON.stringify(this.parent))
        formData.difficultLevel = this.difficultLevel
        putAction('/production/edit', formData).then(res => {
          if (res.success) {
            self.$message.success({ content: '保存成功' })
            self.getDifficultyFactor()
            self.isEdit = false
          }
        })
        //保存
      } else {
        this.isEdit = true
      }
    },
    async getProduction() {
      const res = await getAction('/production/get', {
        id: this.parent.id
      })
      this.difficultLevel = res.data.difficultLevel
    },
    async getStageList() {
      const res = await getAction(`/production/get_production_stage?id=${this.parent.id}`)
      if (res.data && res.data.length) {
        res.data.forEach(item => {
          if (this.userInfo.id == item.userId) {
            this.stages.push(item)
          }
        })
      }
      const rest = await getAction('platform/get_platform_stage', { id: this.parent.platformId })
      if (rest.data && rest.data.length) {
        rest.data.forEach(item => {
          this.stages.forEach(i => {
            if (item.positionId == i.positionId) {
              this.positions.push(i)
            }
          })
        })
      }
      this.isJianzhiOrJiaodui = this.positions.some(item => {
        return item.positionName == '监制' || item.positionName == '校对'
      })
      this.isTranslatorOrProofreader = this.positions.some(item => {
        return item.positionName == '翻译' || item.positionName == '制作'
      })
      if (
        this.userInfo.roles.some(item => {
          return item.roleCode == 'admin' || item.roleCode == 'boss'
        })
      ) {
        this.isJianzhiOrJiaodui = true
        this.isTranslatorOrProofreader = true
      }
    },
    review: simpleDebounce(async function(diffItem, value) {
      this.reviewSim(diffItem, value)
    }, 500),
    async reviewSim(diffItem, value) {
      this.btnLoading = true
      let list = [
        {
          operation: 'DOWN',
          value,
          version: this.factorStatus.find(item => item.type == diffItem.type).version,
          id: this.factorStatus.find(item => item.type == diffItem.type).id,
          productId: this.parent.productionId,
          buttonName: '系数评审'
        }
      ]
      const res = await putAction('/v2/difficultyFactor/status/process/batch', list)
      if (res.success) {
        this.$message.success('评审成功')
        this.getDifficultyFactor()
      }
      this.btnLoading = false
    },
    noLocalReview: simpleDebounce(async function(type) {
      this.noLocalReviewSim(type)
    }, 500),
    async noLocalReviewSim(type) {
      let factor = this.formData.difficulties?.[0].difficulties?.[0].categories?.[0].options?.find(i => {
        return i.title == this.difficultLevel
      }).factor
      this.btnLoading = true
      let arr = this.factorStatus.filter(item => {
        return item.type == type || item.type == 'BUILDER'
      })
      let list = []
      if (arr.length) {
        list = [
          {
            operation: 'DOWN',
            version: this.factorStatus.find(item => item.type == type).version,
            id: this.factorStatus.find(item => item.type == type).id,
            type,
            value: factor || 0,
            productId: this.parent.productionId
          }
        ]
      } else {
        list = [
          {
            type,
            value: factor || 0,
            productId: this.parent.productionId
          }
        ]
      }

      const res = await putAction('/v2/difficultyFactor/status/process/batch', list)
      /* const res = await getAction(
        `/difficultyFactor/confirm?productId=${this.parent.productionId}&type=${type}&value=${factor}`
      ) */
      try {
        if (res.success) {
          this.$message.success('评审成功')
          this.getDifficultyFactor()
        }
        this.btnLoading = false
      } catch (err) {
        this.btnLoading = false
      }
    },
    reject: simpleDebounce(async function(diffItem) {
      this.rejectSim(diffItem)
    }, 500),
    async rejectSim(diffItem) {
      this.btnLoading = true
      let list = [
        {
          operation: 'UP',
          version: this.factorStatus.find(item => item.type == diffItem.type).version,
          id: this.factorStatus.find(item => item.type == diffItem.type).id,
          productId: this.parent.productionId,
          buttonName: '驳回'
        }
      ]
      const res = await putAction('/v2/difficultyFactor/status/process/batch', list)
      if (res.success) {
        this.$message.success('驳回成功')
        this.getDifficultyFactor()
      }
      this.btnLoading = false
    },
    submit: simpleDebounce(async function(diffItem, value) {
      this.submitSim(diffItem, value)
    }, 500),
    async submitSim(diffItem, value) {
      this.btnLoading = true
      let list = [
        {
          productId: this.parent.productionId,
          type: diffItem.type,
          value,
          buttonName: '提交'
        }
      ]
      if (this.factorStatus.find(item => item.type == diffItem.type)) {
        list.forEach(item => {
          item.id = this.factorStatus.find(item => item.type == diffItem.type).id
        })
      }
      const res = await putAction('/v2/difficultyFactor/status/process/batch', list)
      if (res.success) {
        this.$message.success('提交成功')
        this.getDifficultyFactor()
      }
      this.btnLoading = false
    },
    async getDifficultyFactor() {
      const res = await getAction('/difficultyFactor/all?productId=' + this.parent.productionId)
      try {
        this.factorStatus = res.data || []
        this.getProduction()
      } catch (err) {
        console.log(err)
      }
    },
    getDetail() {
      getAction('/production/get', {
        id: this.parent.id
      }).then(({ data }) => {
        this.platform = data.platform
        this.spinning = false
      })
    },
    async saveDifficultyFactor() {
      let arr = this.formData.difficulties?.map(item => {
        return { ruleId: item.id, productId: this.parent.productionId, value: item.specialFactorValue }
      })
      const res = await postAction('/factorView/updateSpecialFactor', arr)
      if (res.code == 200) {
        this.getFormData()
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg)
        this.btnLoading = false
      }
    },
    difficultyFactorValueEdit(diffItem) {
      if (diffItem.isEdit) {
        diffItem.isEdit = false
      } else {
        diffItem.isEdit = true
      }
      this.$forceUpdate()
    },
    openClick(child) {
      if (child.isOpen) {
        child.isOpen = false
      } else {
        child.isOpen = true
      }
      this.$forceUpdate()
    },
    async getFormData() {
      this.spinning = true
      const { productionId } = this.parent
      const res = await getAction('/factorView/product', {
        productionId
      })
      try {
        if (res.code == 200) {
          this.formData = res.data
          this.formData.difficulties.forEach((item, index) => {
            if (item.stages?.some(stage => stage.stageName == '翻译')) {
              item.type = 'TRANSLATOR'
            } else if (item.stages?.some(stage => stage.stageName == '制作')) {
              item.type = 'BUILDER'
            }
          })
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        this.spinning = false
        this.$message.error(err)
      }
    },
    getCalcRange(data) {
      let text = ''
      let unit = ''
      if (data.unit) {
        switch (data.unit) {
          case 'EACH':
            unit = '个'
            break
          case 'CHAPTER':
            unit = '话'
            break
          case 'VOLUME':
            unit = '部'
            break
          case 'PAGE':
            unit = '页'
            break
          case 'WORD':
            unit = '字'
            break
          case 'THOUSAND':
            unit = '千字'
            break
          case 'GRID':
            unit = '格'
            break
          case 'PERCENT':
            unit = '%'
            break
        }
      }
      return unit
    }
  }
}
</script>

<style lang="less" scoped>
.btn-list {
  display: flex;
  justify-content: end;
  position: absolute;
  right: 100px;
}
/deep/ .coef_item .ant-form-item-control-input-content {
  display: flex !important;
}
::-webkit-scrollbar-button {
  height: 4px;
}
.chapter-list-div {
  padding-left: 11px;
}
.chapter-list {
  font-size: 12px;
  margin-left: 6px;
  color: rgb(167, 166, 166);
  overflow: hidden;
  > div {
    float: left;
    margin-left: 19px;
    margin-top: 8px;
    > div {
      width: 2.6vw;
    }
  }
}
/deep/ .ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
}

/deep/ .ant-carousel .custom-slick-arrow {
  font-size: 12px;
  color: gray;
  opacity: 0.3;
}
/deep/ .ant-carousel .custom-slick-arrow:before {
  display: none;
}
/deep/ .ant-carousel .custom-slick-arrow:hover {
  color: black;
}

/deep/ .ant-carousel .slick-slide h3 {
  color: gray;
}
.open-hover:hover {
  cursor: pointer;
  color: var(--theme-color);
}
</style>
